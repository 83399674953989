
@import "@/styles/variables.scss";

.sign-up {
  display: flex;
  justify-content: center;
  flex: 1;
  align-items: center;
  flex-direction: column;
  margin-top: 30px;

  &__registration-subtext {
    margin-top: 10px;
  }

  &__buttons-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 40px 0 20px 0;
    font-size: 16px;

    &-button {
      font-size: 16px !important;
      height: 70px !important;
    }

    &-separator {
      height: 100px;
      width: 1px;
      background-color: $dark-color;
      margin: 0 100px;
    }
  }

  &__warning-message {
    position: absolute;
    left: 10%;
    top: 100%;
    border: 1px solid $primary-color;
    min-height: 35px;
    margin-top: 5px;
    margin-left: 14px;
    width: 150px;
    padding: 10px 15px;
    color: $dark-color;
    font-size: 13px;
    background-color: $light-color;
    box-shadow: 0 0 20px 0 $primary-color;
    line-height: 1.5;
    font-family: $primary-font;
    font-weight: 700;
    z-index: 100;
  }
}
