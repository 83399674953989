
@import "@/styles/variables.scss";

//позволяет dropdown-листу выскакивать за границы окошка
.v--modal-box {
  overflow: visible !important;
}
.sbl-modal-row-last {
	margin-bottom: 15px;
}
.certificate-login {
  height: 85%;
  background-color: $popup-background-color;
  color: $popup-font-color;

  &-next {
    min-width: 25%;
  }

  &-select-wrap {
    width: 80%;

    .vs__search {
        color: $input;
    }
  }
}
