
@import "@/styles/variables.scss";

.registration-fail {
  display: flex;
  justify-content: center;
  flex: 1;
  align-items: center;
  flex-direction: column;
  margin-top: 100px;

    &__head-text {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 40px;
    }

    &__subhead-text {
    margin-top: 20px;
    margin-bottom: 20px;
    }
    
    & button,
    & a {
      width: 400px !important;
      margin-top: 20px;
      text-align: center;
    }
}
