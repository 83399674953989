
@import "@/styles/variables.scss";
.error-modal {
	background-color: $popup-background-color;
	color: $popup-font-color;

	.sbl-items-group-last-row{
		gap:10px;
	}
	.sbl-inner-iframe {
		width: 100%;
		border-style: ridge;
		border-radius: 0.5em;
	}

	button {
		max-width: 150px;
	}

	&-message {
		display: flex;
		flex-direction: row;
		align-items: center;
		word-break: break-word;

		img {
			margin-right: 10px;
			width: 40px;
		}

		&-text {
			white-space: pre-line;
			overflow-y: auto;
			padding-right: 10px;
		}
	}

	&-text {
		text-align: justify;
	}
}
