
@import "@/styles/variables.scss";

.sbl-card {
  width: 100px;
  height: 100px;
}

.spinner-background {
  display:flex;
  justify-content: center;
  position: fixed;
  box-sizing: border-box;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 999;
  opacity: 1;
}

.spinner {
  position: fixed;
  top: 35%;
  box-shadow: 0px 8px 20px rgba(0,0,0,.2);
  
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 25px;

  &-container {
    min-width: $spinnerSize;
    min-height: $spinnerSize;
  }

  &-caption-container {
    padding-top: 25px;
  }

  &-body {
    height: $spinnerSize;
    width: $spinnerSize;
    border: 1px solid transparent;
    border-radius: 50%;
    border-top: 2px solid $primary-color;
    animation: spin 2s linear infinite;
    position: relative;

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 6px;
      bottom: 6px;
      left: 6px;
      right: 6px;
      border-radius: 50%;
      border: 2px solid transparent;
    }

    &::before {
      border-top-color: $lighter-color;
      animation: 2.5s spin linear infinite;
    }
    &::after {
      border-top-color: $secondary-color;
      animation: spin 1.2s linear infinite;
    }

    @keyframes spin {
      from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
  }
}
