
@import "@/styles/variables.scss";

.confirmation-email-expired {
  display: flex;
  justify-content: center;
  flex: 1;
  align-items: center;
  flex-direction: column;
  margin-top: 100px;

  &__head-text {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 40px;
  }

  &__subhead-text {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  &__buttons-container {
    display: flex;
    justify-content: space-between;
  }

  & button,
  & a {
    width: 400px !important;
    margin: 20px;
    text-align: center;
  }
}
