
@import "@/styles/elements.scss";

@media only screen and (max-width: 478px) {
	.sbl-modal {
		min-width: 293px;
	}

	:deep(.sbl-modal-header-h1) {
		width: 50%;
		font-size: 19px;
	}

	.confirm-sms {
		&__common-text {
			width: 50%;
			font-size: 14px;
		}

		&__buttons-container {
			font-size: 14px;
		}
	}
}

@media only screen and (min-width: 478px) {
	.sbl-modal {
		min-width: 478px;
	}

	:deep(.sbl-modal-header-h1) {
		font-size: 24px;
	}

	.confirm-sms {
		&__common-text {
			font-size: 16px;
		}

		& button,
		& span,
		& p,
		& a {
			font-size: 16px;
		}
	}
}

.sbl-modal {
	max-width: 600px;
	padding: 40px;
	background-color: #F5F8FB;
	border: 1px solid #D8E1E8;
	border-radius: 20px;
}

:deep(.modal-close-button) {
	width: 20px;
	height: 20px;
	right: 20px;
	top: 20px;
}

:deep(.sbl-modal-header-h1) {
	color: #104752;
    text-align: center;
    font-family: "SBSansDisplay-Regular";
    font-weight: 400;
}

.sbl-form-control-error {
	margin: auto;
}

.sbl-form-control-container {
	width: 90%;
	align-self: center;
}

.sbl-common-text {
	margin: auto;
	text-align: center;
}

.sbl-control-label {
	text-align: center;
}

.sbl-form-group {
	width: 100%;
	margin-top: 10px;
}

.single-input {
	height: 61px;
	padding: 15px 20px 10px 20px;
	margin-top: 5px;
	margin-bottom: 10px;
	border: 1px solid $input;
	border-radius: 8px;
	min-width: 10px;
	width: 75%;
	letter-spacing: 19.2px;
	text-align: center;
	color: #212529;
	text-align: center;
	font-family: "SBSansUI-Regular";
	font-size: 32px;
	font-weight: 400;
	align-self: center;
}

.confirm-sms {
	display: flex;
	align-items: center;
	flex-direction: column;

	&__common-text {
		color: #104752;
		text-align: center;
		font-family: "SBSansDisplay-Regular";
		font-weight: 400;
		display: block;
		margin-top: 10px;
	}
	&__confirm-code {
		font-size: 25px !important;
		font-family: Arial regular;
	}

	&__buttons-container {
		flex-direction: column;
		width: 84% !important;
		color: #104752;

		& button,
		& span,
		& p,
		& a {
			width: 100% !important;
			margin-top: 20px;
			text-align: center;
			font-family: "SBSansDisplay-Regular";
			font-weight: 400;
			line-height: 120%;
		}

		& button:disabled {
			color: #212529;
		}
	}
}

.sbl-form-control-error {
	font-size: 14px !important;
	text-align: center;
}

.smart-captcha-model {
	width: 100%;
}

