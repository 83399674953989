
@import "@/styles/variables.scss";
@import "@/styles/elements.scss";

@media (min-width: 864px) {

	.sbl-base-container {
		margin: 15vh auto;
		width: 40%;
		min-width: 864px;
		border-radius: $border-rad;
		box-shadow: none;
	}

	.sbl-medium-button {
		width: 150px;
	}
	.sbl-checkbox-spacer {
		margin-bottom: 20px;
	}
	.sbl-checkbox-spacer:last-child {
		margin-bottom: 0px;
	}

	.sbl-checkbox-spacer > .sbl-small-text {
		margin-left: 5px;
	}
}

@media (max-width: 864px) {
	.sbl-base-container {
		margin: 15vh auto;
		width: 80%;
		min-width: 320px;
		border-radius: $border-rad;
		box-shadow: none;
	}

	.sbl-items-group-multiple > .sbl-items-group {
		width: 100%;
	}
	.sbl-items-group-multiple {
		flex-direction: column;
	}

	.sbl-medium-button {
		width: 100%;
	}
}
.sbl-signing {
	
	font-family: "SB Sans Display";

	.sbl-med-text {
		font-size: 14px;
		font-weight: 400;
		color: #293C4D;
	}
	.sbl-h2-text {
		font-size: 24px;
		font-weight: 400;
		color: #293C4D;
	}

	.sbl-card-caption {
		display: flex;
		justify-content: center;
		margin-bottom: 30px;
	}

	.sbl-card {
		padding-top: 15px;
		padding-bottom: 15px;
		border-radius: 20px;
		border: 1px solid #D8E1E8;
		background-color: #FFFFFF;
		margin-bottom: 30px;
	}

	.sbl-warning-card {
		display: flex;
		flex-direction: row;
		background: white;
		height: auto;
		width: 100%;
		border: 1px solid #D8E1E8;
		border-radius: 16px;
		margin-bottom: 30px;
	}

	.sbl-warning-image {
		min-width: 36px;
		min-height: 36px;
		margin-left: 20px;
		background: no-repeat center;
		background-image: url(~@/assets/images/tip.svg);
	}

	.sbl-show-image {
		min-width: 50px;
		min-height: 30px;
		width: 50px;
		height: 30px;
		background: no-repeat center;
		border: 1px solid #D8E1E8;
		border-radius: 100px;
		margin-left: 20px;
		cursor: pointer;
		background-image: url(~@/assets/images/follow.svg);
	}

	.sbl-checkbox {
		content: "";
		display: inline-block;
		width: 100%;
		height: 20px;
		background: url(~@/assets/images/check-box-off.svg) no-repeat;
		max-width: 21px;
	}

	.sbl-checkbox:checked {
		background: url(~@/assets/images/check-box-on.svg) no-repeat;
	}

	.sbl-small-text {
		font-size: 16px;
		line-height: 28px;
	}

	.sbl-items-group-multiple {
		margin-top: 0;
	}

	.sbl-info {
		padding: 20px;
	}

	.sbl-button-group {
		margin-top: 30px;
		margin-bottom: 15px;

		button:first-child {
			margin-left: auto;
		}

		button:last-child {
			margin-right: auto;
		}
	}

	#passFormGoup {
		margin-bottom: 20px;
	}
}
