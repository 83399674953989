
@import "@/styles/variables.scss";

.base-page {
  &__header {
      width: 100%;
      z-index: 9999;
      height: calc(30px + 3%);
      position: fixed;
      background: white;
      box-shadow: 0 0 0.5em 0 $dark-color;

      &__logo {
        position: absolute;
        top: calc(-15px + 50%);
        left: calc(-133px + 35%);

        & img {
          height: 1.8em;
        }
      }

      &__phone {
          position: absolute;
          top: calc(-12px + 30% );
          text-align: right;
          right: calc(-130px + 35%);
          

        &-number {
          font-variant-numeric: lining-nums;
          font-family: $additional-info-font;
          font-size: 20px;
          color: $dark-color;
          text-decoration: none;
        }

        &-number:hover {
          text-decoration: underline;
        }

        &-text {
          font-size: 12px;
          color: $dark-grey;
        }
      }
  }

  &__layout {
    display: flex;
    min-height: 100vh;
    min-width: 100%;
    flex-direction: column;
    justify-content: space-between;
    overflow-x: auto;
    font-size: 16px;
    font-family: $primary-font;
  }

  &__footer {
    padding: 10px 0 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient(
      180deg,
      rgba(221, 255, 231, 0),
      rgba(221, 255, 231, 255)
    );
  }
}
