@import "variables.scss";

/* Уберем рамку у всех кнопок */
input:focus,
select:focus,
button:focus {
	outline: 0;
}

input:disabled,
label:disabled,
.sbl-form-group_disabled,
select:disabled {
	background-color: $unactive-control-color;
	cursor: auto;
	color: gray;
}

select::placeholder,
input::placeholder {
	color: $lighter-color;
}

/* Основа страниц */

.sbl-container {
	max-width: 1130px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
}


/* Заголовки */
.sbl-h1 {
	font-size: 43px;
	color: #000;
	font-family: $secondary-font;
}

.sbl-h2 {
	font-size: 26px;
	color: #000;
	font-family: $secondary-font;
	align-self: center;
}

.sbl-common-text {
	line-height: 1.75;
	font-size: 14px;
}


/* Стили кнопок */
.sbl-button {
	background-color: #2B2B2B;
	font-family: $primary-font;
	font-size: 14px;
	color: #FFFFFF;
	height: 70px;
	width: 270px;
	border-radius: 35px;
	border: none;
	cursor: pointer;
	transition: all 0.2s ease;

	&:not(:disabled):hover {
		background-color: $primary-color;
	}

	&:not(:disabled):active {
		background-color: #dcce00;
	}
}

.sbl-blue-button {
	background-color: $secondary-color;

	&:not(:disabled):hover {
		background-color: $secondary-color-hover;
	}

	&:not(:disabled):active {
		background-color: $secondary-color-active;
	}

	&:disabled {
		background-color: $secondary-color-disabled;
	}
}

.sbl-green-button {
	background-color: $primary-color;

	&:not(:disabled):hover {
		background-color: $primary-color-hover;
	}

	&:not(:disabled):active {
		background-color: $primary-color-active;
	}

	&:disabled {
		background-color: $primary-color-disabled;
	}
}
.sbl-sberBusinnesId-button {
	background-color: $sberBusinessId-color;

	&:not(:disabled):hover {
		background-color: $sberBusinessId-color-hover;
	}

	&:not(:disabled):active {
		background-color: $sberBusinessId-color-active;
	}

	&:disabled {
		background-color: $sberBusinessId-color-disabled;
	}
}
.sbl-sberId-button {
	background-color: $sberId-color;

	&:not(:disabled):hover {
		background-color: $sberId-color-hover;
	}

	&:not(:disabled):active {
		background-color: $sberId-color-active;
	}

	&:disabled {
		background-color: $sberId-color-disabled;
	}
}
.sbl-business-button {
	div{
		display: flex;
		justify-content: center;
		align-items: center;
		
		img{
			height: 28px;
			margin-left: -15px;
			padding-right: 5px;
		}
	}
}
.sbl-white-button {
	background-color: #FFF;
	border: 1px solid #8E8E8E;
	color: #8E8E8E;

	&:not(:disabled):hover {
		color: #000;
		background-color: #FFF;
		border: 1px solid #000;
	}

	&:not(:disabled):active {
		color: $primary-color;
		background-color: #FFF;
		border: 1px solid $primary-color;
	}
}
.sbl-gray-button {
	background-color: #8E8E8E;
	color: #000;

	&:not(:disabled):hover {
		color: #000;
		background-color: #FFF;
		border: 1px solid #000;
	}

	&:not(:disabled):active {
		color: $primary-color;
		background-color: #FFF;
		border: 1px solid $primary-color;
	}
}
.sbl-medium-button {
	align-self: center;
	width: 100%;
	height: 40px !important;
}


/* Карточка */
.sbl-card {
	display: flex;
	flex-direction: column;
	background: white;
	height: auto;
	width: 100%;
	border-radius: $border-rad;
}

.sbl-base-container {
	margin: 15vh auto;
	width: 30%;
	min-width: 350px;
	border-radius: $border-rad;
    box-shadow: 0px 8px 20px rgba(black, 0.05);
}

.sbl-items-group {
	margin-top: 15px;
	display: flex;
	flex-direction: column;
	width: 80%;
	align-self: center;

	&-last-row {
		margin-bottom: 15px;
	}

	&-multiple {
		margin-top: 15px;
		display: flex;
		flex-direction: row;
		width: 80%;
		align-self: center;
	}
	&-spacer {
		max-width: 3%;
		width: 3%;
	}
	&-micro-spacer {
		max-width: 1%;
		width: 1%;
	}
}


.rounded-corners {
	border-radius: $border-rad;
}


.sbl-link {
	text-decoration: none;
	color: $secondary-color;
	transition: all 0.2s ease;
}
.sbl-link-spacing {
	padding-left: 0.5em;
}

.sbl-link:not(:disabled):hover {
	color: $secondary-color-hover;
}

.sbl-link:not(:disabled):active {
	color: $secondary-color-active;
}

.sbl-black-link {
	color: #000;
	transition: all 0.2s ease;
}

	.sbl-black-link:not(:disabled):hover {
		color: #21a038;
	}

	.sbl-black-link:not(:disabled):active {
		color: #dcce00;
	}


/* Группа контролов */
.sbl-form-group {
	margin-top: 15px;
	display: flex;
	flex-direction: column;
	width: 80%;
	align-self: center;
}

.self-align-center {
	align-self: center;
}

.auto-width {
	width: auto;
}

.sbl-rounded-button {
	border-radius: 48px;
}

.sbl-control-label {
	font-size: 13px;
}

.sbl-suggestion-item {
    border: none
}

.sbl-h2-text {
	font-size: 24px;
	line-height: 32px;
}

.sbl-h1-text {
	font-size: 20px;
    line-height: 28px;
}

.sbl-large-text {
	font-size: 18px;
    line-height: 24px;
}

.sbl-med-text {
	font-size: 15px;
    line-height: 20px;
}

.sbl-small-text {
	font-size: 12px;
    line-height: 16px;
}

/*Для IE*/
.explicitFlex {
	flex: 1 1 0% !important;
}

.sbl-control-label-bold {
	font-weight: bold;
	font-size: 15px !important;
}

.sbl-form-control-container {
	display: flex;
	position: relative;
	
	input:focus ~ .floating-placeholder, 
	input:disabled ~ .floating-placeholder, 
	input:not(:focus):valid ~ .floating-placeholder,
	input:-webkit-autofill ~ .floating-placeholder
	{
		left: 20px;
		top: 10px;
		font-size: 12px;
		opacity: 1;
		color: $input-hover;
	}
	.floating-placeholder {
		position: absolute;
		pointer-events: none;
		left: 20px;
    	top: 30px;
		transition: 0.2s ease all;
		color: $input;
	}

	.floating-error {
		position: absolute;
		top: 75px;
		font-size: 12px;
		color: $red;

		& ~ input, 
		& ~ input:hover  {
			border: 1px solid $red;
		}
	}

	.password-actions {
		cursor: pointer;
		position: absolute;
		top: 25px;
		right: 20px;

		width: 28px;
		height: 28px;
		background-repeat: no-repeat;
		background-position: center;
		
		&-not-active {
			cursor: unset;
			background-image: url(~@/assets/images/password-eye-not-active.svg);
		}

		&-show {
			background-image: url(~@/assets/images/password-eye.svg);
		}

		&-hide {
			background-image: url(~@/assets/images/password-eye-crossed.svg);
		}
	}

	.change-password-actions {
		cursor: pointer;
		position: absolute;
		top: 15px;
		right: 15px;

		width: 28px;
		height: 28px;
		background-repeat: no-repeat;
		background-position: center;
		
		&-not-active {
			cursor: unset;
			background-image: url(~@/assets/images/password-eye-not-active.svg);
		}

		&-show {
			background-image: url(~@/assets/images/password-eye.svg);
		}

		&-hide {
			background-image: url(~@/assets/images/password-eye-crossed.svg);
		}
	}
}

.sbl-form-control {
	display: flex;
	flex: 1;
	height: 40px;
	padding: 15px 20px 10px 20px;
	font-size: 16px;
	margin-top: 5px;
	border: 1px solid $input;
	border-radius: 8px;
	min-width: 10px;
	font-family: $primary-font;
	width: 100%;

	&:not(:disabled):hover{
		border: 1px solid $input-hover;
	}

	&-password {
		padding-right: 70px;
	}
}

.sbl-form-control-error {
	color: red;
	font-size: 12px;
	margin-top: 5px;
}

.sbl-select {
	height: 35px;
	cursor: pointer;

	border: 1px solid $input;
	border-radius: 8px;

	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;

	background: url(~@/assets/images/down-arrow.png);
	background-position-x: 97%;
    background-position-y: 50%;
	background-origin: padding-box;
	background-repeat: no-repeat;

	padding-right: 450px;
	
	&-option {
		border: 1px solid $input;
		border-radius: 8px;
	}
}

	.sbl-select::-ms-expand {
		display: none;
	}

	.sbl-select:disabled {
		background: url(~@/assets/images/down-arrow.png);
		background-position-x: 97%;
		background-position-y: 50%;
		background-repeat: no-repeat;
	}

button:disabled,
button[disabled] {
	background-color: #cccccc;
}

.sbl-checkbox {
	margin: 3px;
	cursor: pointer;
	appearance: none;
}

	.sbl-checkbox {
		content: "";
		display: inline-block;
		width: 100%;
		height: 20px;
		background: url(~@/assets/images/check-box-off-hover.svg) no-repeat;
		max-width: 21px;
	}

	.sbl-checkbox:checked {
		background: url(~@/assets/images/check-box-on.svg) no-repeat;
	}

/* Обязательность/корректность/ошибка контролов */
.sbl-required:after {
	content: "*";
	color: red;
	padding-left: 1px;
}

.sbl-accepted-field {
	background: url(~@/assets/images/accepted.png) no-repeat scroll 7px 7px;
	background-position: right 10px bottom 9px;
}

.sbl-error-field {
	background: url(~@/assets/images/error.png) no-repeat scroll 7px 7px;
	background-position: right 10px bottom 9px;
}

.sbl-field-error-message {	
	position: absolute;
	left: 100%;
	top: 0;
	min-height: 35px;
	margin-top: 5px;
	margin-left: 14px;
	width: 150px;
	padding: 10px 15px;
	color: #000;
	font-size: 12px;
	background-color: #ffffff;
	line-height: 1.5;
	font-family: $sans-display-regular;
	z-index: 100;
	border: solid 1px #D8E1E8;
	border-radius: 12px;
}

	.sbl-field-error-message ul {
		padding: 0 20px;
		margin: 10px 0;
	}

		.sbl-field-error-message ul li {
			margin-top: 5px;
		}

.sbl-field-error-message_xl {
	width: 250px;
}

.sbl-field-error-message__header {
	color: red;
}

.sbl-arrow-box:after,
.sbl-arrow-box:before {
	right: 100%;
	top: 24px;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}

.sbl-arrow-box:after {
	border-right-color: #ffffff;
	border-width: 14px;
	margin-top: -14px;
}

.sbl-arrow-box:before {
	border-right-color: #D8E1E8;
	border-width: 15px;
	margin-top: -15px;
}

.wrapper-button {
    opacity: 1;
    position: relative;
    background: linear-gradient(124deg, #21a038, #067c21, #13bb04, #09c522, #05b65d, #09a07a, #258d05, #2eb35a, #035203);
    background-size: 1800% 1800%;
    -webkit-animation: rainbow 18s ease infinite;
    -z-animation: rainbow 18s ease infinite;
    -o-animation: rainbow 18s ease infinite;
    animation: rainbow 18s ease infinite;
}

.wrapper {
    height: 100%;
    width: 100%;
    display: block;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: .2;
    position: fixed;
    z-index: 1;
    background: #000000;
}


@-webkit-keyframes rainbow {
    0% {
        background-position: 0% 82%
    }
    50% {
        background-position: 100% 19%
    }
    100% {
        background-position: 0% 82%
    }
}

@-moz-keyframes rainbow {
    0% {
        background-position: 0% 82%
    }
    50% {
        background-position: 100% 19%
    }
    100% {
        background-position: 0% 82%
    }
}

@-o-keyframes rainbow {
    0% {
        background-position: 0% 82%
    }
    50% {
        background-position: 100% 19%
    }
    100% {
        background-position: 0% 82%
    }
}

@keyframes rainbow {
    0% {
        background-position: 0% 82%
    }
    50% {
        background-position: 100% 19%
    }
    100% {
        background-position: 0% 82%
    }
}

#autosuggest__input {
	position: relative;
	display: block;
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	height: 100%;
	width: 100%;	
}

#autosuggest__input.autosuggest__input--open, #autosuggest__input:hover {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

  .autosuggest__results-container {
	position: relative;
	width: 100%;
  
  }
  .autosuggest__results {
	font-weight: 300;
	margin: 0;
	position: absolute;
	z-index: 10000001;
	width: 100%;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
	padding: 0px;
    overflow-y: scroll;
    overflow-x: hidden;
	max-height: 400px;
	min-width: 700px;	
  }
  .autosuggest__results ul {
	list-style: none;
	padding-left: 0;
	margin: 0;
    background: #fff;	
  
  .autosuggest__results .autosuggest__results-item {
	cursor: pointer;
	padding: 10px;
  }
  
  }
  
  #autosuggest {
	width: 100%;	  
	ul:nth-child(1) > .autosuggest__results-before {
		border-top: none;
	}
  }

  .autosuggest__results .autosuggest__results-before {
	opacity: 0.5;
	font-size: 11px;
	margin-left: 0;
	padding: 15px 13px 5px;
	border-top: 1px solid lightgray;
  }
  .autosuggest__results .autosuggest__results-item:active,
  .autosuggest__results .autosuggest__results-item:hover,
  .autosuggest__results .autosuggest__results-item:focus,
  .autosuggest__results .autosuggest__results-item.autosuggest__results-item--highlighted {
	background-color:  #3EA45A;
	border: 1px solid  #2E8F47
  }
