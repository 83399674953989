
@import "@/styles/elements.scss";
.sbl-inn-control {
    margin: 0 auto;
    padding-left: 40px;
    padding-right: 40px;
}
.sbl-inn-header {
    font-size: 24px;
    text-align: center;
    padding: 40px 40px 20px 40px;
}
.sbl-inn {
  &__inn-code {
    font-size: 25px !important;
    font-family: Arial regular;
  }

  &__buttons-container {
    margin-top: 20px;
    margin-bottom: 40px;
    display: flex;
    justify-content: center;

    & > *:not(:first-child) {
      margin-left: 5px;
      margin-right: 100px;
    }
    & > *:first-child {
      margin-left: 100px;
      margin-right: 5px;
    }

  }
}
