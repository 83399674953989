
@import "@/styles/variables.scss";

.sbl-login-by-certificate-button {
	background-color: #ffffff;
	color: #212529;
	border: 1px solid #D8E1E8;

	&:not(:disabled):hover {
		background-color: #ffffff;
		background: linear-gradient(73.1deg, #21BA72 10.98%, #0087CD 87.65%);
		-webkit-background-clip: text;
  		-webkit-text-fill-color: transparent;
		border: 1px solid #D8E1E8;
	}

	&:not(:disabled):active {
		background-color: #EEFCFA;
		background: linear-gradient(73.1deg, #21BA72 10.98%, #0087CD 87.65%);
		-webkit-background-clip: text;
  		-webkit-text-fill-color: transparent;
		border: none;
	}

	&:disabled {
		background-color: #D8E1E8;
		color: #293C4D;
		border: none;
	}
}

.sbl-sign-rounded-button {
	height: 48px!important;
	padding: 8px;
	font-size: 14px;
	font-weight: 500;
}

.sign-up-register-method{
	text-align: center;
}

.sign-info {
	margin-top: 65px;
}

.sbl-base-container {
	background: #D8E1E8;
}
.sign {
	height: 450px;
	
	.sbl-sign-button:disabled {
		font-weight: bold;
		background: white;
		color: black;
		font-size: 20px;
	}

	.sbl-sign-button {
		color: $not-active-switch;
		background: none;
		width: 50%;
		height: 4em;
		border: none;
		font-family: "SBSansUI-Regular";
		line-height: 133%;

		&-login {
			border-radius: $border-rad $border-rad 0 0;
		}

		&-register {
			border-radius: $border-rad $border-rad 0 0;
		}
	}

	.sign-up {
		&-signIn {
			margin-top: 150px;
		}

		&-body {
			border-radius: 0 0 $border-rad $border-rad;
		}

		&-choose-caption {
			margin-top: 15%;
			margin-bottom: 5%;
		}
	}

	.sign-in {
		&-body {
			border-radius: 0 0 $border-rad $border-rad;
		}

		&__capcha {
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
	.rounded-all {
		padding-top: 20px;
		border-radius: $border-rad $border-rad $border-rad $border-rad;
	}
}
.sign-in-warning {
	background: white;
	text-align: center;
	border-radius: 16px;
	padding: 10px;
    font-size: 15px;
    margin-top: 10px;
}
