
@import "@/styles/variables.scss";

.cookie-notice {
    display: none;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    margin-left: auto;
    margin-right: auto;
    max-width: 600px;
    height: 80px;
    background: #FFFFFF;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.05);
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;

    &__text {
      position: absolute;
      width: 70%;
      height: 54px;
      left: 38px;
      top: 24px;
      font-size: 12px;
      font-family: $sans-display-regular;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      color: #293C3D;
    }

    &__close {
      box-sizing: border-box;
      padding: 10px 30px;
      gap: 10px;
      position: absolute;
      width: 127px;
      height: 42px;
      left: calc(50% - 100px/2 + 200.5px);
      top: calc(50% - 42px/2);
      background: #FFFFFF;
      border: 1px solid #D8E1E8;
      border-radius: 29px;
      font-family: $sans-display-bold;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      cursor: pointer;
      text-align: center;
      align-items: center;
      display: flex;
    }

    &__close:active {
        background: #f4f6f9;
    }
}
   @media (max-width: 767.98px) {
    .cookie-notice {
         height: 15%;
         max-width: 90%;
          &__text{
            width: 100%;
            left: 5%;            
          }
          &__close {
            left: calc(50% - 127px/2 );
            top: calc(75% - 42px/2);
          }
    }
   
}
