
@import "@/styles/elements.scss";
.pin-input-container {
	padding:10px;
	display:flex;
	justify-content: space-around;
	width: 100%;

	@media only screen and (max-width: 478px) {
		.pin-input {
			min-width: 35px;
			min-height: 70px;
		}
	}

	@media only screen and (min-width: 478px) {
		.pin-input {
			min-width: 56px;
			min-height: 86px;
		}
	}

	.pin-input {
		flex:0;
		padding:2% 2% 2% 3%;

		padding: 0;
		border-radius: 10px;
		border: 2px solid #D8E1E8;
		background: #FFFFFF;

		color: #212529;
		text-align: center;
		font-family: "SBSansUI-Regular";
		font-size: 32px;
		font-weight: 400;

		&:not(:disabled):hover{
			border: 2px solid #9FA8AD;
		}
	}
	.pin-input:focus {
		border: 2px solid #777777;
	}
}

