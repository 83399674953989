
@import "@/styles/elements.scss";
.sbl-phone-control {
	margin: 0 auto;
	padding-left: 40px;
	padding-right: 40px;
}
.sbl-phone-header {
	color: #104752;
	font-size: 24px;
	text-align: center;
	padding: 40px 40px 20px 40px;
}
.sbl-phone {
	&__phone-code {
		color: #104752;
		font-size: 25px !important;
		font-family: Arial regular;
	}

	&__buttons-container {
		margin-top: 20px;
		margin-bottom: 40px;
		display: flex;
		justify-content: center;

		.sbl-button {
			width: 200px;
		}
	}
}
