
@import "@/styles/variables.scss";

.change-password {
	.pass-hint-item {
		padding-bottom: 10px;
		font-family: 'SBSansDisplay-Regular';
	}
	.pass-hint-item span{
		padding-left: 10px;
	}
	.pass-hint-message {
		padding-bottom: 10px;
		font-family: 'SBSansDisplay-Bold';
	}

	.pass-hint-header {
		padding-bottom: 10px;
		font-family: 'SBSansDisplay-Bold';
	}
	img {
		vertical-align: middle;
	}
	.sbl-card{
		display:flex;
		align-items: center;
		.sbl-h1{
			text-align: center;
			margin-top: 10px;
			font-size: 35px;
		}
		.sbl-common-text{
			text-align: center;
			max-width:90%;
		}
		.sbl-form-group{
			display: block;
			.sbl-form-control{
				padding:2px 10px 2px 10px;
			}
		}
	}
	justify-content: center;
	margin:15vh auto;
	align-items: center;
	flex-direction: column;
	max-width: 550px;
	&__head-text {
		margin: 10px 0 30px;
		color: $primary-color;
		font-size: 22px;
		font-weight: bold;
	}

	&__continue {
		margin-top: 30px;
		width: 70%;
	}

	&__capcha {
		 display: flex;
		 align-items: center;
		 justify-content: center;
	}
}
