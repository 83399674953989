
@import "@/styles/variables.scss";

.registration-confirm {
	display: flex;
	justify-content: center;
	flex: 1;
	align-items: center;
	flex-direction: column;
	margin-top: 100px;
}

.exception-txt {
	font-size: 24px;
	padding: 30px;
}
