
@import "@/styles/variables.scss";
@import "@/styles/elements.scss";

@media (min-width: 864px) {
	.sbl-base-container {
		margin: auto;
		width: 600px;
		border-radius: $border-rad;
		box-shadow: none;
	}
}

@media (max-width: 864px) {
	.sbl-base-container {
		margin: auto;
		width: 50%;
		min-width: 320px;
		border-radius: $border-rad;
		box-shadow: none;
	}

	.sbl-h2-text {
		font-size: 19px !important;
	}
}


.sbl-signing-fail {
	font-family: "SB Sans Display";

	.sbl-med-text {
		font-size: 14px;
		font-weight: 400;
		color: #293C4D;
	}

	.sbl-h2-text {
		font-size: 22px;
		font-weight: 600;
		color: #293C4D;
	}

	.sbl-card {
		padding-top: 20px;
		padding-bottom: 20px;
		border-radius: 20px;
		border: 1px solid #D8E1E8;
		background-color: #FFFFFF;
	}

	.sbl-medium-button {
		width: 200px;
	}
}
