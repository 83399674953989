$default-color: #ffffff;
$primary-color: #0A917F;
$secondary-color: #2F7E9D;
$dark-color:  #000;
$light-color: #FFF;
$lighter-color: #C7C7C7;
$danger-color: #ff6d4a;
$success-color:#7ed321;
$unactive-color: #8e8e8e;
$unactive-control-color: #e6e6e6;
$dark-border:#626262;
$sberId-color: #21a038;
$sberBusinessId-color: #107F8C;
$popup-background-color: #F5F8FB;
$popup-font-color: #104752;

/*Roboto-Regular*/
$primary-font: 'SBSansUI-Regular';
$secondary-font: 'SBSansUI-Regular';
$additional-info-font: 'SBSansUI-Regular';

/*SBSansDisplay*/
$sans-display-regular: 'SBSansDisplay-Regular';
$sans-display-bold: 'SBSansDisplay-Bold';

/*UI v2.0*/
$border-rad: 16px;
$spinnerSize: 50px;

$not-active-switch: #212529;

$secondary-color-hover: #569AB3;
$secondary-color-active: #6CBFDB;
$secondary-color-disabled: #D8E1E8;

$primary-color-hover: #10B29C;
$primary-color-active: #34CBB7;
$primary-color-disabled: #D8E1E8;

$sberId-color-hover: #1E9032;
$sberId-color-active: #21A038;
$sberId-color-disabled: #90CF9B;

$sberBusinessId-color-hover: #21A19A;
$sberBusinessId-color-active: #107F8C;
$sberBusinessId-color-disabled: #D8E1E8;

$dark-grey: #77838A;
$red: #FF0000;

$input: #C1C9CD;
$input-hover: #777777;