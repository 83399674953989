

.certificate-login {
  &__signature {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0 0;

    &-text {
      align-self: center;
    }
  }

  &__remember-password {
    align-self: flex-end;
    margin-top: 10px;
    font-size: 14px;
  }

  &__buttons-container {
    display: flex;
    flex-direction: column;
    width: 100% !important;
    margin-top: 20px;

    &-button {
      width: 100% !important;
      margin-top: 20px;
    }
  }

  &__button {
    width: 100% !important;
    margin-top: 20px;
  }

  &__signature-text {
    text-align: center;
  }

  &__buttons-container {
    margin-top: 20px;
  }
}
