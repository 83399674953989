
@import "@/styles/variables.scss";

.password-recovery-confirm {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	&__subhead-text {
		margin-top: 20px;
	}

	&__buttons-container {
		display: flex;
		flex-direction: column;
		margin: 20px 0;

		& button,
		& a {
			width: 100% !important;
			margin-top: 20px;
			text-align: center;
		}

		& a {
			color: $unactive-color;
			text-decoration: none;
			font-weight: bold;
			margin-top: 40px;
		}
	}

	&__link {
		text-decoration: underline;
		font-weight: 100;
		color: $primary-color;
	}

	&__password {
		margin-top: 20px;
		text-align: center;
	}

	&__capcha {
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
