
@import "@/styles/variables.scss";



.autosuggest-floating-placeholder{
  top: 20px;
}

.sbl-h2-text, 
.personal-data-agreement {
  padding-top: 15px;
}

.sbl-items-group {
	width: 90%;

	&-multiple {
		width: 90%;
  }

  &-signUpButtons {
    width: 40%;
  }

}

.personal-data {
  top: 15%;
  width: 60%;

  &-agreement {
    font-family: $primary-font;
  
    &-header {
      text-align: center;
    }

    &-check {
      font-weight: bold;
      cursor: pointer;

      & span {
        margin-left: 10px;
        cursor: pointer;
        margin-top: auto;
        margin-bottom: auto;
      }
      & label {
        display: flex;
        cursor: pointer;
      }
      &-second {
        margin-top: 15px;
      }
    }
  }
  #inn {
    padding-right: 15px;
  }

  #kpp {
    padding-left: 15px;
  }

  button {
    margin: 0 10px 0 10px;
  }
}
