
$light-blue: #D8E1E8;

.lookup-modal-header {
    margin-top: 40px;

    .sbl-modal-header-h1 {
        color: #104752;
        font-size: 24px;
    }
}

.sbl-lookup-table {
    height: 100%;
    width: stretch;
    border-collapse: collapse;
    background: white;
    box-shadow: 4px 5px 9px 3px rgba(167.98, 187.49, 216.75, 0.20);
    border-radius: 20px;
    margin: 50px;
    margin-bottom: 15px;

    thead, tbody { 
        display: block;
        border-left: 1px $light-blue solid;
        border-right: 1px $light-blue solid;
    }

    tbody {
        max-height: 300px;
        overflow-y: auto;
        overflow-x: hidden;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        border-bottom: 1px $light-blue solid;
    }

    tbody td, thead th {
        width: 50%;
    }

    tbody td:first-child, thead th:first-child {
        width: 10px;
    }

    tr {
        padding: 10px;
    }

    th, td {
        text-align: left;
    }

    th, td:not(:first-child) {
        padding: 20px;       
    }

    tr:has(input[type=radio]:checked) {
        background: rgba(21, 167, 148, 0.05);
    }

    .sbl-table-head {
        background: linear-gradient(73deg, rgba(33, 186, 114, 0.10) 0%, rgba(0, 135, 205, 0.10) 100%);
        border-bottom: 0.50px $light-blue solid;
        border-top: 1px $light-blue solid;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;

        th {
            color: #104752;
            font-size: 16px;
            font-weight: 400;
            line-height: 22px;
            word-wrap: break-word;
        }
    }
}

.choose-button {
    padding: 10px 30px 10px 30px
}

// Переключатели
.sbl-radio {
    float: left;
    width: 20px;
    height: 20px;
    position: relative;
    cursor: pointer;
    margin-left: 24px;
}

.sbl-radio input[type=radio] {
    opacity: 0;
    z-index: 1;
    position: absolute;
    cursor: pointer;
}

.sbl-radio div {
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background: #104752;
    border: 0.5px #104752 solid;
    cursor: pointer;
}

.sbl-radio div::before {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background: white;
    cursor: pointer;
}

.sbl-radio input[type=radio]:checked + div {
    background: linear-gradient(73deg, #21BA72 0%, #0087CD 100%);
    border: 0;
    cursor: pointer;
}

.sbl-radio input[type=radio]:checked + div::before {
    top: 7px;
    left: 7px;
    width: 6px;
    height: 6px;
    cursor: pointer;
}
