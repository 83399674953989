
@import "@/styles/variables.scss";
@import "@/styles/elements.scss";

.password-recovery {

	font-family: "SB Sans Display";

	.sbl-med-text {
		font-size: 14px;
		font-weight: 400;
		color: #293C4D;
	}
	.sbl-h2-text {
		font-size: 24px;
		font-weight: 400;
		color: #293C4D;
	}
	.sbl-card {
		padding-top: 15px;
		padding-bottom: 15px;
			border-radius: 20px;
			border: 1px solid #D8E1E8;
			background-color: #FFFFFF;
	}
	.sbl-form-control-container input{
		margin-bottom: 15px;
	}

	&__capcha {
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
