
@import "@/styles/variables.scss";

.personal-data-modal {
  font-family: $primary-font;
  
  &-header {
    text-align: center;
  }

  #cancel, #continue {
    margin: 0px 10px 0px 10px;
  }

  &-check {
    font-weight: bold;
    cursor: pointer;

    & span {
      margin-left: 10px;
      cursor: pointer;
      margin-top: auto;
      margin-bottom: auto;
    }
    & label {
      display: flex;
      cursor: pointer;
    }
    &-second {
      margin-top: 15px;
    }
  }
}
